import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import formatPickupTime from '../../../utils/format-time';
import getEndTime from '../../../utils/format-end-time';
import formatPickupDate from '../../../utils/format-pickup-date';
import { sampleSale } from '../../../constants';
const { kitchenId: sampleSaleKitchenId } = sampleSale;

const useStyles = makeStyles((theme) => ({
  confirmation: {
    marginTop: '2em',
    textAlign: 'center',
    '& .order-id': {
      margin: '2em'
    }
  }
}));

const renderSampleSaleText = (kitchenId) => {
  if (kitchenId !== sampleSaleKitchenId) {
    return null;
  }
  return (
    <Fragment>
      <Box className='order-id'>
        <Typography>
          Only ONE order per associate is allowed! Please do not place another
          order.
        </Typography>
      </Box>
      <Box className='order-id'>
        <Typography>
          Please SAVE or PRINT this confirmation for your records.
        </Typography>
      </Box>
    </Fragment>
  );
};

const formatTimeForPayment = (pickupTime) => {
  return formatPickupTime(pickupTime);
};

const renderPhone = (phone) => {
  if (!phone)
    return <Typography component='span'>the number provided.</Typography>;

  return (
    <NumberFormat displayType='text' format='+1 ###-###-####' value={phone} />
  );
};

const OrderConfirmation = (props) => {
  const classes = useStyles();
  const { location } = props;

  if (!location || (location && !location.state)) {
    /*
       we have not state, which could be due to a page refresh. but
       is more likely due to a bookmark on an order confirmation
       screen that is not the result of an order. we could load the
       order again, but that could lead to issues with old order
       dates. so get them out of here and back to a new order screen.
    */
    return <Redirect to="/" />;
  }
  // state should be the new order item
  const { state } = location;
  const { kitchenId = '', email = '' } = state;
  const phone = state && state.phone ? renderPhone(state.phone) : '';
  const orderDate = formatPickupDate(state.orderDate);
  const pickupTime = formatTimeForPayment(state.pickupTime);
  const endTime = getEndTime(state.pickupTime);
  const sampleSaleText = renderSampleSaleText(kitchenId);
  
  return (
    <Box>
      <Box marginBottom={1} className={classes.confirmation}>
        <Typography variant='h2' color='primary'>
          Thank you. Your shopping request has been confirmed.
        </Typography>
        {sampleSaleText}
        <Box className='order-id'>
          <Typography>Here is your Session ID: #{state.orderNumber}</Typography>
        </Box>
        <Box className='order-id'>
          <Typography>Your shopping date is {orderDate} at {pickupTime}-{endTime} at</Typography>
          <Typography>DC6</Typography>
          <Typography>3425 Morse Crossing Rd.</Typography>
          <Typography>Columbus, OH 43219</Typography>
        </Box>
        <Box className='order-id'>
          <Typography>
            Confirmation will be sent via text to {phone}{' '}
            {kitchenId === sampleSaleKitchenId
              ? `and ${email}. Additional details will be emailed to you.`
              : null}
          </Typography>
        </Box>
      </Box>
        <Box>
          <Box className={classes.confirmation}>
        <Typography style={{ fontWeight: 'bold' }}>SAMPLE SALE GUIDELINES</Typography>
        </Box>
        <br/><br/>
        <Typography variant="body2">Our values and expectations that govern our working together apply to our associate sale. Associate events like this one, which allow us to experience our brands at dramatically discounted costs, hinge on the understanding that products we buy through the sale will never be resold anywhere else. Reselling product is a serious violation of our Code of Conduct and could result in termination.<br/><br/></Typography>
        <Box className={classes.confirmation}>
        <p><Typography style={{ fontWeight: 'bold' }}>ADDITIONAL GUIDELINES TO KNOW</Typography><br/><br/></p>
        </Box>
        <p>
          <ul style= { { listStyleType: 'disc', paddingLeft: '1rem' } }>
            <li><Typography variant="body2">Participation in this sale is voluntary. Associates must shop on their own time. Please plan your time accordingly.</Typography></li>
            <li><Typography variant="body2">Anyone leaving the line for any reason must rejoin at the back of the line.</Typography></li>
            <li><Typography variant="body2">Bring your employee badge or photo ID to enter the sale.</Typography></li>
            <li><Typography variant="body2">Secure all cell phones, keys, and coats when entering the sale.</Typography></li>
            <li><Typography variant="body2">This sale is associate only. No guests permitted.</Typography></li>
            <li><Typography variant="body2">No food or drinks permitted inside the sale.</Typography></li>
            <li><Typography variant="body2">Parking will open 30 minutes prior to the start of your shopping session. Please do not arrive any earlier. Line queuing will start 15 minutes prior to the shopping session start.</Typography></li>
            <li><Typography variant="body2">Check in closes 30 minutes prior to the end of the shopping session. Re-entry to the sale closes 30 minutes prior to the end of the shopping session.</Typography></li>
            <li><Typography variant="body2">Associates must pay by major <Box fontWeight='bold' display='inline'>credit card only</Box> (Visa, Mastercard, Discover). No cash accepted.</Typography></li>
            <li><Typography variant="body2">All sales are final (no returns or exchanges) and not eligible for our associate discount.</Typography></li>
            <li><Typography variant="body2">We reserve the right to limit quantities on all items.</Typography></li>
            <li><Typography variant="body2">Personal items such as large bags, large purses, or backpacks are not permitted inside the sale.</Typography></li>
            <li><Typography variant="body2">Due to the nature of available product (various clothing items in all styles and sizes) we are unable to offer pre-purchased bags or shipping.</Typography></li>
          </ul>
          <br/><br/>
        </p>
      </Box>
    </Box>
);
};

export default OrderConfirmation;
