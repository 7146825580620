import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isWeekend } from 'date-fns';

import isMenuAvailable from '../../../utils/is-menu-available';
import getPickupDate from '../../../utils/get-pickup-date';
import isBlackoutDate from '../../../utils/blackout-dates';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '250px',
    marginBottom: '20px'
  }
}));

const MenuLandingPage = (props) => {
  const classes = useStyles();
  const { match } = props;
  const { path } = match;
  const isClosedForWeekend =
    isWeekend(getPickupDate()) && path !== '/bypass-orders-closed';
  const isBlackedOut = 
    isBlackoutDate(getPickupDate()) && path !== '/bypass-orders-closed';
  const isBreakfastAvailable = isMenuAvailable('BREAKFAST');
  const isLunchAvailable = isMenuAvailable('LUNCH');
  const isSampleSaleAvailable = isMenuAvailable('SAMPLESALE');
  // const breakfastPath =
  //   path === '/bypass-orders-closed'
  //     ? '/bypass-orders-closed/breakfast'
  //     : '/breakfast-menu';
  // const lunchPath =
  //   path === '/bypass-orders-closed'
  //     ? '/bypass-orders-closed/lunch'
  //     : '/lunch-menu';
  const sampleSalePath =
    path === '/bypass-orders-closed'
      ? '/bypass-orders-closed/sample-sale'
      : '/sample-sale';
  if (
    !isSampleSaleAvailable && (
      isClosedForWeekend ||
      isBlackedOut ||
      (!isBreakfastAvailable &&
        !isLunchAvailable &&
        !isSampleSaleAvailable &&
        path !== '/bypass-orders-closed')
      )  
  ) {
    return (
      <Box mt={3}>
        <Typography
          variant='h3'
          color='error'
          className={classes.ordersClosedTitle}
          align='center'
        >
          {isClosedForWeekend
            ? 'Sorry, ordering is currently closed.'
            : 'Sorry, ordering is currently closed.'}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='100%'
      mt={3}
    >
<Typography style={{ fontWeight: 'bold' }}>SAMPLE SALE GUIDELINES</Typography>
  <br/><br/>
  <Typography variant="body2">Our values and expectations that govern our working together apply to our associate sale. Associate events like this one, which allow us to experience our brands at dramatically discounted costs, hinge on the understanding that products we buy through the sale will never be resold anywhere else. Reselling product is a serious violation of our Code of Conduct and could result in termination.<br/><br/></Typography>
  <p><Typography style={{ fontWeight: 'bold' }}>ADDITIONAL GUIDELINES TO KNOW</Typography><br/><br/></p>
  <p>
  <ul style= { { listStyleType: 'disc', paddingLeft: '1rem' } }>
  <li><Typography variant="body2">Participation in this sale is voluntary. Associates must shop on their own time. Please plan your time accordingly.</Typography></li>
      <li><Typography variant="body2">Anyone leaving the line for any reason must rejoin at the back of the line.</Typography></li>
      <li><Typography variant="body2">Bring your employee badge or photo ID to enter the sale.</Typography></li>
      <li><Typography variant="body2">Secure all cell phones, keys, and coats when entering the sale.</Typography></li>
      <li><Typography variant="body2">This sale is associate only. No guests permitted.</Typography></li>
      <li><Typography variant="body2">No food or drinks permitted inside the sale.</Typography></li>
      <li><Typography variant="body2">Parking will open 30 minutes prior to the start of your shopping session. Please do not arrive any earlier. Line queuing will start 15 minutes prior to the shopping session start.</Typography></li>
      <li><Typography variant="body2">Check in closes 30 minutes prior to the end of the shopping session. Re-entry to the sale closes 30 minutes prior to the end of the shopping session.</Typography></li>
      <li><Typography variant="body2">Associates must pay by major <Box fontWeight='bold' display='inline'>credit card only</Box> (Visa, Mastercard, Discover). No cash accepted.</Typography></li>
      <li><Typography variant="body2">All sales are final (no returns or exchanges) and not eligible for our associate discount.</Typography></li>
      <li><Typography variant="body2">We reserve the right to limit quantities on all items.</Typography></li>
      <li><Typography variant="body2">Personal items such as large bags, large purses, or backpacks are not permitted inside the sale.</Typography></li>
      <li><Typography variant="body2">Due to the nature of available product (various clothing items in all styles and sizes) we are unable to offer pre-purchased bags or shipping.</Typography></li>
    </ul>
    <br/><br/>
  </p>
        {/*isLunchAvailable || path === '/bypass-orders-closed' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(lunchPath)}
          className={classes.button}
        >
          Order Lunch
        </Button>
      ) : null}
      {isBreakfastAvailable || path === '/bypass-orders-closed' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(breakfastPath)}
          className={classes.button}
        >
          Order Breakfast
        </Button>
      ) : null */}
      {isSampleSaleAvailable || path === '/bypass-orders-closed/sample-sale' ? (
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={() => props.history.push(sampleSalePath)}
          className={classes.button}
        >
          Acknowledge and Enter
        </Button>
      ) : null}
    </Box>
  );
};

export default MenuLandingPage;
