import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#1769aa',
      contrastText: '#fff'
    },
    success: {
      main: green['500']
    },
    disable: {
      main: grey['300']
    },
    error: {
      main: red['500']
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    h1: {
      fontSize: '32px',
      fontWeight: '600',
      marginBottom: '25px',
      fontFamily: ['"Open Sans"', 'Roboto', 'Arial', 'sans-serif'].join(',')
    },
    h2: {
      fontSize: '28px',
      fontWeight: '600'
    },
    h3: {
      fontSize: '20px',
      fontWeight: '600'
    },
    button: {
      fontWeight: 600
    }
  }
});

export default theme;
