/*
 * makes the time format from the database suitable for display
 * example: 1145 becomes 11:45 AM
 * 1315 becomes 1:15 PM
 */
const formatPickupTime = (timeInput) => {
  const time = timeInput.toString();
  let hour;
  let minutes;
  if (timeInput >= 1000) {
    hour = parseInt(time.substr(0, 2));
    minutes = time.substr(2, 2);
  }
  if (timeInput < 1000) {
    hour = parseInt(time[0]);
    minutes = time.substr(1, 2);
  }
  if (hour > 12) {
    return `${hour - 12}:${minutes} PM`;
  }
  if (hour === 12) {
    return `${hour}:${minutes} PM`;
  } else {
    return `${hour}:${minutes} AM`;
  }
};

export default formatPickupTime;
