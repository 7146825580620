module.exports = {
  featureFlags: {
    disableManageMenu: false,
    disableAddMenuItem: false,
    disableBreakfast: false,
    disableSampleSale: false
  },
  sides: {
    lunch: [
      {
        id: 'side-french-fries',
        name: 'french fries'
      },
      { id: 'side-tater-tots', name: 'tater tots' },
      { id: 'side-macaroni-salad', name: 'macaroni salad' },
      { id: 'side-potato-salad', name: 'potato salad' },
      { id: 'side-none', name: 'no side' }
    ],
    breakfast: [
      {
        id: 'side-bacon',
        name: 'bacon (2)'
      },
      { id: 'side-sausage', name: 'sausage' },
      { id: 'side-none', name: 'no side' }
    ]
  },
  httpOptions: {
    iframeTimeout: 7500
  },
  iframeMaxRetries: 4,
  desiredDateFormats: {
    dynamoFormat: 'yyyy-MM-dd',
    displayFormat: 'EEEE, MM/dd/yy'
  },
  sampleSale: {
    kitchenId: 'VS',
    ordersStart: new Date(2023, 6, 21),
    ordersClose: new Date(2023, 7, 7, 23, 59, 59),
    pickupDates: [new Date(2023, 7, 12), new Date(2023, 7, 13), new Date(2023, 7, 19), new Date(2023, 7, 20), new Date(2023, 7, 26), new Date(2023, 7, 27)],
    orderItemInput: {
      id: '966bf275-a2c8-44bb-844d-b63f1ea0f5b5',
      price: 0,
      name: 'Bath & Body Works Sample Sale',
      itemType: 'NONE',
      sides: []
    }
  }
};
