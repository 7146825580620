import formatPickupTime from './format-time';
const _ = require('lodash');

const getEndTime = (time) => {
  let endTime = formatPickupTime(time + 200);
  // '10:00 XM'
  const endTimeRange = _.last(endTime.split(' '));
  endTime = endTime.replace(' AM', '').replace(' PM', '');
  // '10:00'
  const endFirstTwo = _.first(endTime.split(':'));
  const endLastTwo = _.last(endTime.split(':'));

  if (endFirstTwo === '11' && endLastTwo === '30') {
    return `${endFirstTwo}:${endLastTwo} AM`;
  }

  return `${endFirstTwo}:${endLastTwo} ${endTimeRange}`;
};

export default getEndTime;