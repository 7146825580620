import React, { useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import MenuLandingPage from './menu';
// import LunchMenuScreen from './menu/lunch-menu';
// import BreakfastMenuScreen from './menu/breakfast-menu';
import SampleSaleScreen from './sample-sale';
import PaymentScreen from './payment';
import ConfirmationScreen from './confirmation/view.js';
import OrderErrorScreen from './error/view.js';

const OrderContainer = (props) => {
  const [orderDetails, setOrderDetailes] = useState({});
  const saveOrderDetails = (values) => {
    setOrderDetailes({
      ...values
    });
  };
  const { location } = props;
  const redirectTo = location.pathname.includes('kitchen')
    ? location.pathname
    : '/';

  return (
    <Container maxWidth='sm'>
      <Switch>
        <Route
          exact
          path='/'
          render={(props) => <MenuLandingPage {...props} />}
        />
        <Route
          exact
          path='/bypass-orders-closed'
          render={(props) => (
            <MenuLandingPage {...props} saveOrderDetails={saveOrderDetails} />
          )}
        />
        {/* <Route
          exact
          path='/lunch-menu'
          render={(props) => (
            <LunchMenuScreen {...props} saveOrderDetails={saveOrderDetails} />
          )}
        />
        <Route
          exact
          path='/bypass-orders-closed/lunch'
          render={(props) => (
            <LunchMenuScreen {...props} saveOrderDetails={saveOrderDetails} />
          )}
        />
        <Route
          exact
          path='/breakfast-menu'
          render={(props) => (
            <BreakfastMenuScreen
              {...props}
              saveOrderDetails={saveOrderDetails}
            />
          )}
        />
        <Route
          exact
          path='/bypass-orders-closed/breakfast'
          render={(props) => (
            <BreakfastMenuScreen
              {...props}
              saveOrderDetails={saveOrderDetails}
            />
          )}
        /> */}
        <Route
          exact
          path='/sample-sale'
          render={(props) => (
            <SampleSaleScreen {...props} saveOrderDetails={saveOrderDetails} />
          )}
        />
        <Route
          exact
          path='/bypass-orders-closed/sample-sale'
          render={(props) => (
            <SampleSaleScreen {...props} saveOrderDetails={saveOrderDetails} />
          )}
        />
        <Route
          exact
          path='/payment'
          render={(props) => (
            <PaymentScreen
              {...props}
              orderDetails={orderDetails}
              saveOrderDetails={saveOrderDetails}
            />
          )}
        />
        <Route
          exact
          path='/confirmation/:orderId'
          render={(props) => <ConfirmationScreen {...props} />}
        />
        <Route
          exact
          path='/order-error'
          render={(props) => <OrderErrorScreen {...props} />}
        />
        <Redirect to={redirectTo} />
      </Switch>
    </Container>
  );
};

export default withRouter(OrderContainer);
