import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
const text = 
    'By checking this box, I understand and agree to comply with the above terms.';
const Waiver = () => {
  return (
    <Fragment>
      <Typography>
        {text}{' '}
      </Typography>
    </Fragment>
  );
};
export default Waiver;