import React from 'react';
import _ from "lodash";
import { useQuery } from '@apollo/react-hooks';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import getAvailablePickupTimesQuery from '../../kitchen/queries/get-pickup-times';
import formatPickupTime from '../../../utils/format-time';
import { desiredDateFormats, sampleSale } from '../../../constants/index';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    marginBottom: '30px'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  }
}));

const PickupTimeSelect = (props) => {
  const classes = useStyles();
  const { displayFormat, dynamoFormat } = desiredDateFormats;
  const {
    selectedKitchen,
    handleChange,
    values,
    pickupDate,
    menuType,
    showPickupDate = true,
    location
  } = props;
  const _pickupDate = format(pickupDate, dynamoFormat);
  const { data, loading } = useQuery(getAvailablePickupTimesQuery, {
    variables: {
      input: {
        kitchenId: selectedKitchen,
        menuType,
        orderDate: _pickupDate,
        location: location
      }
    },
    fetchPolicy: 'network-only'
  });
  const getEndTime = (time) => {
    let endTime = formatPickupTime(time + 200);
    // '10:00 XM'
    const endTimeRange = _.last(endTime.split(' '));
    endTime = endTime.replace(' AM', '').replace(' PM', '');
    // '10:00'
    let endFirstTwo = _.first(endTime.split(':'));
    let endLastTwo = _.last(endTime.split(':'));
  
    if(endFirstTwo === '11' && endLastTwo === '30') {
      
      return `${endFirstTwo}:${endLastTwo} AM`;
    }
    
    return `${endFirstTwo}:${endLastTwo} ${endTimeRange}`; 
  };


  const renderTimeItem = (pickupTime) => {
    const { time } = pickupTime;
    let label = formatPickupTime(time);
    if (sampleSale.kitchenId && selectedKitchen === sampleSale.kitchenId) {
      const end = getEndTime(time);
      label = `${label} - ${end}`;
    }
    return (
      <MenuItem key={`time-${time}`} value={time}>
        {label}
      </MenuItem>
    );
  };

  const renderTimes = (times) => {
    const results = [];
    const sortedTimes = times.sort((a, b) => a.time - b.time);
    sortedTimes.forEach((time) => {
      results.push(renderTimeItem(time));
    });
    return results;
  };

  if (loading) {
    return <CircularProgress size='2em' className={classes.progress} />;
  }
  if (data) {
    const { getAvailablePickupTimes: pickupTimes } = data;
    const timeOptions = renderTimes(pickupTimes);
    const dateDisplay = showPickupDate ? (
      <Typography gutterBottom>
        Pickup Date: {format(pickupDate, displayFormat)}
      </Typography>
    ) : null;
    return (
      <Box mt={2}>
        {dateDisplay}
        <FormControl className={classes.select}>
          <InputLabel id='time-select'>Select a Shopping Time</InputLabel>
          <Select
            labelId='time-select'
            name='pickupTime'
            value={values.pickupTime}
            onChange={handleChange}
          >
            {timeOptions}
          </Select>
        </FormControl>
      </Box>
    );
  }
  return null;
};

export default PickupTimeSelect;
