import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { Formik } from 'formik';
import {
  Button,
  TextField,
  Paper,
  Typography,
  FormHelperText,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import cleanTypeName from '../../../utils/cleanTypeName.js';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Waiver from './waiver';
import validationSchema from '../menu/validation-schema';
import isMenuAvailable from '../../../utils/is-menu-available';
import createOrderMutation from '../mutations/create-order.js';
import ComplianceTerms from './complianceTerms';
import CodeOfConduct from './code-of-conduct';
import TimeSelect from '../menu/time-select';
import { desiredDateFormats, sampleSale } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    width: '100%'
  },
  submitButton: {
    marginTop: '1em',
    marginBottom: '3em'
  },
  progress: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    zIndex: 1
  },
  ordersClosedTitle: {
    marginBottom: '1.5em'
  },
  smsDisclaimer: {
    marginTop: '1em'
  },
  divider: {
    height: '1px',
    marginTop: '1em'
  },
  itemSection: {
    margin: '1.5em 0em 0em 0em',
    padding: '1em 0',
    borderTop: 'solid 1px black',
    borderBottom: 'solid 1px black'
  },
  codeSection: {
    padding: '1em 0',
    margin: '0em 0em 2em 0em'
  }
}));

const SampleSaleScreen = (props) => {
  const classes = useStyles();
  const [callCreateOrder] = useMutation(createOrderMutation);
  const { saveOrderDetails } = props;
  const { dynamoFormat, displayFormat } = desiredDateFormats;
  const kitchenId = 'VS';
  const company = ['VS & Co Home Office and Customer Care Center', 
                   'VS & Co Distribution Center'
                  ];
  const { match } = props;
  const { path } = match;
  const available = isMenuAvailable('SAMPLESALE');
  const [codeOfConductChecked, setCodeOfConductChecked] = useState(false);
  const [waiverChecked, setWaiverChecked] = useState(false);
  const [itemChecked, setItemChecked] = useState(false);
  const [pickupDisabled, setPickupDisabled] = useState(false);
  
  function handleCompanySelect(e) {
    if(e.target.value === 'Bath & Body Works Outside of Ohio'){      
      setPickupDisabled(true);
    } else {
      setPickupDisabled(false);
    }
  }

  const renderDateOptions = () => {
    const { pickupDates } = sampleSale;
    return pickupDates.map((pickupDate) => {
      return (
        <MenuItem key={pickupDate} value={pickupDate}>
          {format(pickupDate, displayFormat)}
        </MenuItem>
      );
    });
  };

  const renderCompanyOptions = () => {
    return company.map((company) => {
      return (
        <MenuItem key={company} value={company}>
          {company}
        </MenuItem>
      );
    });
  };

  const initialFormValues = {
    firstName: '',
    lastName: '',
    orderDate: '',
    pickupTime: '',
    phone: '',
    email: '',
    associateId: '',
    company: ''
  };

  const handleCheckItem = (event) => {
    const { target } = event;
    const { checked } = target;
    setItemChecked(checked);
  };

  const handleCodeOfConduct = (event) => {
    const { target } = event;
    const { checked } = target;
    setCodeOfConductChecked(checked);
  };

  const handleWaiver = (event) => {
    const { target } = event;
    const { checked } = target;
    setWaiverChecked(checked);
  };

  const proceedToPayment = async (values) => {
    if(pickupDisabled){
      const { pickupDates } = sampleSale;
      values.orderDate = pickupDates[0];
      values.pickupTime = 100; // 1:00AM pickup time is reserved for remote orders
    }
    const _orderDate = format(values.orderDate, dynamoFormat);
    // TODO add item to Dynamo - replace constant with data from query
    const { orderItemInput } = sampleSale;
    const orderObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      pickupTime: values.pickupTime,
      phone: values.phone,
      kitchenId,
      orderDate: _orderDate,
      meta: null,
      associateId: values.associateId,
      company: values.company,
      email: values.email,
      codeOfConduct: true,
      waiver: true,
      items: [orderItemInput]
    };
    saveOrderDetails(orderObject);
    // props.history.push('/payment');
    try {
      const data = {
        amount: 0
      };
      const payload = cleanTypeName({
        ...orderObject,
        ...data,
        promoCode: 'b53c8f3b-2c0d-4c1d-baa0-d4e569b88ae5'
      });

      // const orderWithMeta = {
      //   amount,
      //   meta: {
      //     token: response,
      //     session: sessionData,
      //     payment: data
      //   }
      // };

      const result = await callCreateOrder({ variables: { order: payload } });
      const { data: _result } = result;

      if (!_result.createOrder) {
        // eslint-disable-next-line no-console
        console.warn('problem creating order');
        props.history.push('/order-error');
      }

      const { createOrder } = _result;
      const newOrder = createOrder.pop();

      props.history.push({
        pathname: `/confirmation/${newOrder.id}`,
        state: newOrder
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err.message);
      Sentry.captureException(err);
      props.history.push('/order-error');
    }

  };

  if (!available && path !== '/bypass-orders-closed/sample-sale') {
    return (
      <Box marginTop={3} textAlign='center'>
        <Typography
          variant='h3'
          color='error'
          className={classes.ordersClosedTitle}
        >
          Sorry, Sample Sale registration is currently closed.
        </Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={0}>
      <Typography color='primary' variant='body2'>
        Please fill out your order details below.
      </Typography>
      <Formik
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        onSubmit={proceedToPayment}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors
          } = props;

          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                required
                label='First Name'
                onChange={handleChange}
                value={values.firstName}
                name='firstName'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <TextField
                required
                label='Last Name'
                onChange={handleChange}
                value={values.lastName}
                name='lastName'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <TextField
                required
                label='Associate ID'
                onChange={handleChange}
                value={values.associateId}
                name='associateId'
                type='text'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              <FormControl className={classes.select}>
                <InputLabel 
                  required
                  id='company-select' >
                    Select Company and Location
                </InputLabel>
                <Select
                  required
                  labelId='company-select'
                  name='company'
                  defaultValue={""}
                  onChange={e=>{
                    handleChange(e);
                    handleCompanySelect(e);
                  }}
                >
                {renderCompanyOptions()}
                </Select>
              </FormControl>
              <Typography
                color='primary'
                variant='body2'
                className={classes.smsDisclaimer}
              >
                You will receive a text message with your order confirmation and
                receipt.
              </Typography>

              <NumberFormat
                onValueChange={(val) => setFieldValue('phone', val.value)}
                value={values.phone}
                name='phone'
                type='tel'
                mask='_'
                margin='dense'
                label='Cell Phone Number'
                required
                variant='outlined'
                allowEmptyFormatting
                format='+1 (###) ###-####'
                customInput={TextField}
              />
              {errors && errors.phone ? (
                <FormHelperText error={true}>{errors.phone}</FormHelperText>
              ) : null}
              {/* TODO add validation and/or mask for email field */}
              <TextField
                required
                label='Email'
                onChange={handleChange}
                value={values.email}
                name='email'
                type='email'
                variant='outlined'
                margin='dense'
                className={classes.input}
              />
              {!pickupDisabled ? (
                <FormControl className={classes.select}>
                  <InputLabel id='date-select'>Select a Shopping Date</InputLabel>
                  <Select
                    labelId='date-select'
                    name='orderDate'
                    value={values.orderDate}
                    onChange={handleChange}
                  >
                    {renderDateOptions()}
                  </Select>
                </FormControl>
              ) : null}
              {values.orderDate ? (
                <TimeSelect
                  handleChange={handleChange}
                  values={values}
                  selectedKitchen={kitchenId}
                  pickupDate={values.orderDate}
                  menuType='SAMPLESALE'
                  showPickupDate={false}
                  location={values.company}
                />
              ) : null}
              <Box className={classes.itemSection}>
                <Typography variant='subtitle2'>
                  Please select your order:
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemChecked}
                      onChange={handleCheckItem}
                      name='item'
                      color='primary'
                    />
                  }
                  label='Sample Sale'
                />
              </Box>
              <Box className={classes.codeSection}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={codeOfConductChecked}
                      onChange={handleCodeOfConduct}
                      name='codeOfConduct'
                      color='primary'
                    />
                  }
                  label={<CodeOfConduct />}
                />
              </Box>
              <Box>
                <ComplianceTerms />
              </Box>
              <Box className={classes.codeSection}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={waiverChecked}
                      onChange={handleWaiver}
                      name='waiver'
                      color='primary'
                    />
                  }
                  label={<Waiver />}
                />
              </Box>
              {((values.orderDate && values.pickupTime) || pickupDisabled) &&
              itemChecked &&
              codeOfConductChecked &&
              waiverChecked ? (
                <Button
                  className={classes.submitButton}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Register
                </Button>
              ) : null}
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default SampleSaleScreen;
